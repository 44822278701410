import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/mnt/fstack_dev/nykenshinkai.com/nyksk/src/components/default-mdx-layout.js";
import SEO from "../components/seo";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <SEO title="日本語" mdxType="SEO" />
    <h1>{`日本語`}</h1>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "960px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/static/c3936d51a6cafd7bff9d34d3f5a9f73b/6a068/AEUSKF-2018-womens-individual-winners.jpg",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "74.58333333333333%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "display": "block"
            }
          }}></span>{`
  `}<picture parentName="a">{`
          `}<source parentName="picture" {...{
              "srcSet": ["/static/c3936d51a6cafd7bff9d34d3f5a9f73b/8ac56/AEUSKF-2018-womens-individual-winners.webp 240w", "/static/c3936d51a6cafd7bff9d34d3f5a9f73b/d3be9/AEUSKF-2018-womens-individual-winners.webp 480w", "/static/c3936d51a6cafd7bff9d34d3f5a9f73b/e46b2/AEUSKF-2018-womens-individual-winners.webp 960w"],
              "sizes": "(max-width: 960px) 100vw, 960px",
              "type": "image/webp"
            }}></source>{`
          `}<source parentName="picture" {...{
              "srcSet": ["/static/c3936d51a6cafd7bff9d34d3f5a9f73b/09b79/AEUSKF-2018-womens-individual-winners.jpg 240w", "/static/c3936d51a6cafd7bff9d34d3f5a9f73b/7cc5e/AEUSKF-2018-womens-individual-winners.jpg 480w", "/static/c3936d51a6cafd7bff9d34d3f5a9f73b/6a068/AEUSKF-2018-womens-individual-winners.jpg 960w"],
              "sizes": "(max-width: 960px) 100vw, 960px",
              "type": "image/jpeg"
            }}></source>{`
          `}<img parentName="picture" {...{
              "className": "gatsby-resp-image-image",
              "src": "/static/c3936d51a6cafd7bff9d34d3f5a9f73b/6a068/AEUSKF-2018-womens-individual-winners.jpg",
              "alt": "AEUSKF 2018 womens individual winners",
              "title": "AEUSKF 2018 womens individual winners",
              "loading": "lazy",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              }
            }}></img>{`
        `}</picture>{`
  `}</a>{`
    `}</span></p>
    <p>{`稽古時間、場所、稽古代は英語版のページを御覧ください。`}</p>
    <p>{`剣道経験者、初心者、女性、子供、大歓迎です。`}<br parentName="p"></br>{`
`}{`剣道は大人になってからでも始められます。楽しく稽古をしております。`}</p>
    <p>{`マンハッタンでは子供は大人と一緒に稽古を行います。`}<br parentName="p"></br>{`
`}{`ニュージャージーでは、子供のクラスがあります。`}</p>
    <p>{`日本語でいつでもお問い合わせください。`}</p>
    <p>{`コンタクト 梅村まで`}<br parentName="p"></br>{`
`}{`E メール： `}<a parentName="p" {...{
        "href": "mailto:nykenshinkai@gmail.com"
      }}>{`nykenshinkai@gmail.com`}</a><br parentName="p"></br>{`
`}{`電話：917-626-5918 (電話に出ない場合は、メッセージと電話番号を残してください。折り返し連絡させていただきます。）`}<br parentName="p"></br>{`
`}{`Facebook ページ`}<a parentName="p" {...{
        "href": "https://www.facebook.com/NYKenshinkai/"
      }}>{`https://www.facebook.com/NYKenshinkai/`}</a></p>
    <iframe title="Facebook page plugin" src="https://www.facebook.com/plugins/page.php?href=https%3A%2F%2Fwww.facebook.com%2FNYKenshinkai%2F&tabs=messages%2C%20timeline&width=340&height=500&small_header=true&adapt_container_width=true&hide_cover=false&show_facepile=false&appId=269023560381952" allow="encrypted-media" width="100%" height="500" frameBorder="0" style={{
      "border": "0"
    }}></iframe>

    <style {...{
      "className": "grvsc-styles"
    }}>{`
  .grvsc-container {
    overflow: auto;
    -webkit-overflow-scrolling: touch;
    padding-top: 1rem;
    padding-top: var(--grvsc-padding-top, var(--grvsc-padding-v, 1rem));
    padding-bottom: 1rem;
    padding-bottom: var(--grvsc-padding-bottom, var(--grvsc-padding-v, 1rem));
    border-radius: 8px;
    border-radius: var(--grvsc-border-radius, 8px);
    font-feature-settings: normal;
  }
  
  .grvsc-code {
    display: inline-block;
    min-width: 100%;
  }
  
  .grvsc-line {
    display: inline-block;
    box-sizing: border-box;
    width: 100%;
    padding-left: 1.5rem;
    padding-left: var(--grvsc-padding-left, var(--grvsc-padding-h, 1.5rem));
    padding-right: 1.5rem;
    padding-right: var(--grvsc-padding-right, var(--grvsc-padding-h, 1.5rem));
  }
  
  .grvsc-line-highlighted {
    background-color: var(--grvsc-line-highlighted-background-color, transparent);
    box-shadow: inset var(--grvsc-line-highlighted-border-width, 4px) 0 0 0 var(--grvsc-line-highlighted-border-color, transparent);
  }
  
`}</style>
    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      